import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader";
import { OrbitControls } from "@react-three/drei";

function DaeLoad({ url }) {
  const ref = useRef();
  console.log("u=", process.env.REACT_APP_API + url);
  const collada = useLoader(ColladaLoader, [process.env.REACT_APP_API + url]);
  console.log("m=", collada);
  // this should work, but it doesn't
  useEffect(
    () =>
      collada.forEach((model) =>
        model.scene.traverse(
          (obj) => obj.isMesh && (obj.castShadow = obj.receiveShadow = true)
        )
      ),
    []
  );
  return collada.map(({ scene }, index) => (
    <primitive key={index} object={scene} ref={ref} />
  ));
}

const SceneModel = ({ Model }) => {
  console.log("ici", Model);
  return (
    <div>
      <h3>{Model.alternativeText}</h3>
      <Suspense fallback={null}>
        <Canvas camera={{ position: [0, 5, 15] }} style={{ height: "500px" }}>
          <ambientLight intensity={0.4} />
          <DaeLoad url={Model.url} />
          <OrbitControls
            enablePan={true}
            enableZoom={true}
            enableRotate={true}
            maxDistance={20}
          />
        </Canvas>
      </Suspense>
    </div>
  );
};

export default SceneModel;
