import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import ParalaxButon from "../../components/butons/ParalaxButon";
import { Link } from "react-scroll";
import SliderItem from "./sliderItems";
import NavSlider from "../../components/slider/nav";
import AsideSlider from "../../components/slider/rigthSide";

import "./slider.css";
export function SliderContainer({ lang, staticContext, children }) {
  let [projets, setProjets] = useState(
    staticContext ? staticContext.projects[lang] : []
  );
  let [current, setCurrent] = useState(
    staticContext ? staticContext.projects[lang][0] : {}
  );
  let [currentID, setCurrentID] = useState(staticContext ? 0 : null);
  let [clicked, setClicked] = useState(false);
  let [oldX, setOld] = useState(null);
  let sliderPos = useRef(null);
  useEffect(async () => {
    //let res = await axios.get(process.env.REACT_APP_API+"/data/projets_"+lang+".json")
    let res = await axios.get(
      process.env.REACT_APP_API + "/projets?_sort=order:ASC"
    );
    if (!res.data) return false;
    setProjets(res.data);
    setCurrent(res.data[0]);
    setCurrentID(0);
  }, []);

  function setPos(pos) {
    let target = sliderPos.current;
    target.style.transform =
      "translateX(" + Number(pos) * (-100 / projets.length) + "%)";
    setCurrentID(pos);
    setCurrent(projets[pos]);
  }

  function Next(goBack = false) {
    if (currentID == projets.length - 1) {
      if (typeof goBack == "boolean" && goBack) {
        let target = sliderPos.current;
        target.style.transform = "translateX(0%)";
        setCurrentID(0);
        console.log(projets[0]);
        setCurrent(projets[0]);
      }
    } else {
      console.log("ici");
      let target = sliderPos.current;
      target.style.transform =
        "translateX(" + Number(currentID + 1) * (-100 / projets.length) + "%)";
      setCurrentID(currentID + 1);
      setCurrent(projets[currentID + 1]);
    }
  }

  function Prev() {
    if (currentID == 0) return false;
    let target = sliderPos.current;
    target.style.transform =
      "translateX(" + Number(currentID - 1) * (-100 / projets.length) + "%)";
    setCurrentID(currentID - 1);
    setCurrent(projets[currentID - 1]);
  }
  function handleDown(event) {
    console.log(event);
    setOld(event.pageX ? event.pageX : event.touches[0].pageX);
    setClicked(true);
    sliderPos.current.style.transitionDuration = "0s";
    sliderPos.current.style.cursor = "grabbing";
  }
  function handleUp(event) {
    setOld(null);
    setClicked(false);
    sliderPos.current.style.transitionDuration = "0.6s";
    sliderPos.current.style.cursor = "default";
    let base =
      ((Number(currentID) * (-100 / projets.length)) / 100) *
      (projets.length * window.innerWidth);
    sliderPos.current.style.transform = "translateX(" + base + "px)";
  }
  function handleMove(event) {
    if (!clicked && !oldX) return false;
    try {
      let pageX = event.pageX ? event.pageX : event.touches[0].pageX;
      let t = sliderPos.current;
      let x = pageX - oldX;
      if (x > 0 && currentID == 0) return false;
      if (x < 0 && currentID == projets.length - 1) return false;

      let pourcent = (x * 100) / window.innerWidth;
      if (Math.abs(pourcent) > 30) {
        handleUp();
        if (pourcent < 0) {
          Next();
        } else {
          Prev();
        }
      } else {
        let base =
          ((Number(currentID) * (-100 / projets.length)) / 100) *
          (projets.length * window.innerWidth);
        console.log(base);
        t.style.transform = "translateX(" + (base + x) + "px)";
      }
    } catch (error) {}
  }

  let slider_items = projets.map((p, i) => {
    return <SliderItem lang={lang} current={p} key={i} />;
  });

  return (
    <section id="slider">
      <div className="slider">
        <div
          className="slider_list"
          onMouseUp={handleUp}
          onMouseDown={handleDown}
          onMouseMove={handleMove}
          onTouchStart={handleDown}
          onTouchMove={handleMove}
          onTouchEnd={handleUp}
          style={{ width: projets.length * 100 + "vw" }}
          ref={sliderPos}
        >
          {slider_items}
        </div>
        <div className="container-go-down">
          <ParalaxButon>
            <Link to="about" smooth={true}>
              <div className="down-button"></div>
            </Link>
          </ParalaxButon>
        </div>
        <NavSlider
          setPos={setPos}
          current={currentID}
          length={projets.length}
        />
        <AsideSlider
          arrows={[
            currentID > 0 ? true : false,
            currentID == projets.length - 1 ? false : true,
          ]}
          current={currentID + 1}
          length={projets.length}
          Prev={Prev}
          Next={Next}
          setPos={setPos}
        />
      </div>
    </section>
  );
}
