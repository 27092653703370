import React from "react";
import { Link } from "react-scroll";
import "./index.css";
function NavBar() {
  return (
    <nav>
      <Link to="about" smooth={true}>
        <span>A propos</span>
        <div></div>
      </Link>
      <Link to="projects" smooth={true}>
        <span>Projets</span>
        <div></div>
      </Link>
      <Link to="contact" smooth={true}>
        <span>Contact</span>
        <div></div>
      </Link>
    </nav>
  );
}

export default NavBar;
