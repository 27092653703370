import ReactMarkdown from "react-markdown";

/**
 *
 * @param {{SchemaItems:Array}}
 * @returns
 */
const SchemaGroup = ({ schema_item }) => {
  console.log(schema_item);
  if (!schema_item) return <div></div>;
  return (
    <div className="SchemaGroupContainer">
      {schema_item.map((item) => {
        return (
          <div className="SchemaItems">
            <div className="schema-item-image">
              <img
                src={`${process.env.REACT_APP_API}${
                  item.image.formats != null
                    ? item.image.formats.thumbnail.url
                    : item.image.url
                }`}
                alt={`${process.env.REACT_APP_API}${item.image.alternativeText}`}
              />
            </div>
            <div className="schema-item-verb">
              <ReactMarkdown>{item.content}</ReactMarkdown>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SchemaGroup;
