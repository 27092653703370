import React, { useEffect, useState } from "react";
import "./index.css";

export default function AlbumImageItem({
  isCurrent,
  isNext,
  isPrev,
  data = {},
  zIndex = 10,
  children,
  styleCustom,
}) {
  console.log(styleCustom);
  const [style, setStyle] = useState({ ...styleCustom, zIndex });
  const [dataImage, setData] = useState(data);
  const [className, setClassName] = useState("album-image-item");
  useEffect(() => {
    let next = isNext ? " image-item-next" : "";
    let current = isCurrent ? " image-item-current" : "";
    let prev = isPrev ? " image-item-prev" : "";
    setClassName("album-image-item" + next + current + prev);
    setData(data);
    setStyle({ ...styleCustom, zIndex });
  }, [isCurrent, isNext, data, zIndex]);

  return (
    <div className={className} style={style}>
      <img src={`${process.env.REACT_APP_API}${dataImage.url}`} alt="" />
    </div>
  );
}
