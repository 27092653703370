import React, { useEffect, useState } from "react";
import Logo from "../../components/header/logo";
import { Link } from "react-router-dom";
import "./index.css";
import axios from "axios";
import { AiFillHeart } from "react-icons/ai";

export default function Footer({ logo, copyright }) {
  const [links, setLinks] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        let {
          data: { links },
        } = await axios.get(`${process.env.REACT_APP_API}/footer`);
        if (!links) throw new Error("No data");
        setLinks(links);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  return (
    <>
      <footer>
        <div className="left">
          <Logo logo={logo} />
        </div>
        <div className="center">
          {links.map((link) => {
            if (link.external) {
              return (
                <Link to={link.link}>
                  <span>{link.display}</span>
                  <div></div>
                </Link>
              );
            } else {
              return (
                <a href={link.link} target="_blank">
                  <span>{link.display}</span>
                  <div></div>
                </a>
              );
            }
          })}
        </div>
        <div className="right">
          <span className="copyrigth">
            {copyright} {new Date().getFullYear()}
          </span>
        </div>
      </footer>
      <div className="credit">
        <a href="https://axelgodefroy.fr" target="_blank">
          <span>made with </span>
          <i>
            <AiFillHeart />
          </i>
          <span>by my brother</span>
        </a>
      </div>
    </>
  );
}
