
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import React, {useEffect,useRef,useState} from 'react'
import Marker from "../../assets/svg/marker.svg"
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = 'pk.eyJ1IjoiYXhlbDc3ZyIsImEiOiJja3M0bWdldHAxZjAwMnZwZTc4bmx5MzdqIn0.MUrFoC0L79M7RrWt2tKDbQ';
mapboxgl.workerClass = MapboxWorker;


export default function Map({coord}) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(coord.lng);
    const [lat, setLat] = useState(coord.lat);
    const [zoom, setZoom] = useState(12);
    const [load , setLoad] = useState(true)
    useEffect(() => {
        if(!load) return; // initialize map only once
        console.log("loadMap");
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/axel77g/ckqhyhgs701lh17o06swes5yn',
            center: [lng, lat],
            zoom: zoom
        });
        setLoad(false)
        const marker = document.createElement("img")
        marker.setAttribute("src",Marker)
        marker.className = "marker"
        new mapboxgl.Marker(marker)
        .setLngLat([lng, lat])
        .addTo(map.current)
    },[lng,lat,zoom]);

    return (
        <div>
            <div ref={mapContainer} className="map-container">
                <div className="map" ref={map}></div>
            </div>
        </div>
    )
}
