import React from "react";
import { Link } from "react-router-dom";
function Info({ slug, title, description, lang }) {
  return (
    <div className="title">
      <h2>{title}</h2>
      <span>{description}</span>
      <Link to={"/project/" + slug}>
        <div className="bouton">{lang == "fr" ? "Voir Plus" : "See More"}</div>
      </Link>
    </div>
  );
}

export default Info;
