import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import ParalaxButon from "../../components/butons/ParalaxButon";
import { Link } from "react-router-dom";

import Markbook from "../../assets/svg/markbook.svg";
import MarkbookCheck from "../../assets/svg/markbookCheck.svg";

import "./index.css";
import "../project/index";
import ReactMarkdown from "react-markdown";

/*export default function AutoHtmlPageContainer(staticContext=null) {
    let {page} = useParams()
    let location = useLocation()
    console.log(location);
    const [jsonHtmlData, setJsonHtmlData] = useState(staticContext ? staticContext.html : {})
    useEffect(()=>{
        const getData = async () => {
            try {
                console.log(`${process.env.REACT_APP_API_DOC}${page}`);
                const {data} = await axios(`${process.env.REACT_APP_API_DOC}${page}`)
                if(!data) throw new Error("No data");
                setJsonHtmlData(data);
                
            } catch (error) {
                console.error(error);
            }
        }
        
        getData()
    },[])
    useEffect(()=>{
        const goToHash = () =>{
            if(!location.hash) return;
            let element = document.getElementById(location.hash.replace("#",""));
            if(!element) return;
            element.scrollIntoView({behavior: "smooth"});
        }
        goToHash()
    },[jsonHtmlData])
    const copyLink = ({currentTarget})=>{
        let input = currentTarget.children[2]
        input.select();
        document.execCommand("copy");
    }

    if(!jsonHtmlData) return <div className="padding-body"><h1>Recherche...</h1></div>
    return (
        <div className="padding-body article-auto">
            <div className="back-container">
                <ParalaxButon>
                    <Link to="/"><div className="goback"><div>
                    Home</div></div></Link>
                </ParalaxButon>
            </div>
            <h1 className="title-page-auto">
                {jsonHtmlData.title}
            </h1>
            <h2 className="subtitle-page-auto">
                {jsonHtmlData.subtitle}
            </h2>
            {jsonHtmlData.content.map(c=>{
                return <div id={c.id} className="content-container-page-auto">
                    <div onClick={copyLink} className="markbook-container">
                        <img src={MarkbookCheck} className="hover-markbook" alt="markbook" />
                        <img src={Markbook} className="no-hover-markbook" alt="markbook" />
                        <input type="text" value={window.location.origin + window.location.pathname + "#"+c.id} />
                    </div>
                    <h3 className="part-title-auto-page">
                        {c.title}
                    </h3>
                    {c.paragraphs.map(para=>{
                        return <p className="para-auto-page">
                            {para}
                        </p>
                    })}
                </div>
            })}
        </div>
    )
}*/

const AutoHtmlPageContainer = () => {
  let { page } = useParams();
  let location = useLocation();
  const [dataPage, setDataPage] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        let { data } = await axios.get(
          `${process.env.REACT_APP_API}/pages?slug=${page}`
        );
        if (!data.length) throw new Error("No data");
        setDataPage(data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const goToHash = () => {
      if (!location.hash) return;
      let element = document.getElementById(location.hash.replace("#", ""));
      if (!element) return;
      element.scrollIntoView({ behavior: "smooth" });
    };
    goToHash();
  }, [dataPage]);
  const copyLink = ({ currentTarget }) => {
    let input = currentTarget.children[2];
    input.select();
    document.execCommand("copy");
  };
  return (
    <div>
      {dataPage?.content ? (
        <>
          <div className="padding-body article-auto">
            <div className="back-container">
              <ParalaxButon>
                <Link to="/">
                  <div className="goback">
                    <div>Home</div>
                  </div>
                </Link>
              </ParalaxButon>
            </div>
            <ReactMarkdown
              components={{
                // Map `h1` (`# heading`) to use `h2`s.
                em: ({ node, ...props }) => (
                  <i style={{ color: "red" }} {...props} />
                ),
                h3: ({ node, ...props }) => {
                  let id = props.children[0];
                  return (
                    <div id={id} className="content-container-page-auto">
                      <div onClick={copyLink} className="markbook-container">
                        <img
                          src={MarkbookCheck}
                          className="hover-markbook"
                          alt="markbook"
                        />
                        <img
                          src={Markbook}
                          className="no-hover-markbook"
                          alt="markbook"
                        />
                        <input
                          type="text"
                          value={
                            window.location.origin +
                            window.location.pathname +
                            "#" +
                            id
                          }
                        />
                      </div>
                      <h3 {...props}></h3>
                    </div>
                  );
                },
              }}
            >
              {dataPage.content}
            </ReactMarkdown>
          </div>
        </>
      ) : (
        <div className="padding-body">
          <h1>Recherche...</h1>
        </div>
      )}
    </div>
  );
};

export default AutoHtmlPageContainer;
