import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AlbumImageContainer from "../../components/AlbumImage/AlbumImageContainer";
import axios from "axios";
import ParalaxButon from "../../components/butons/ParalaxButon";
import { Link } from "react-router-dom";
import SimilarProject from "../../components/SimilarProj/SimilarProject";
import ImgPreview from "../../components/globals/imagePreview";
import Paragraph from "./subComponents/paragraph";
import SchemaGroup from "./subComponents/schemaGroup";
import Scene from "./subComponents/3DModel";

import "./index.css";

const contentTable = [
  {
    type: "projet.paragraph",
    component: Paragraph,
  },
  {
    type: "projet.schema-group",
    component: SchemaGroup,
  },
  {
    type: "projet.3-d-model",
    component: Scene,
  },
];

export default function ProjectContainer({
  lang = "fr",
  staticContext = null,
}) {
  let { id } = useParams();
  id = staticContext ? staticContext.id : id;
  const [projets, setProjets] = useState(
    staticContext ? staticContext.projets : []
  );
  const [targetProjet, setTargetProj] = useState(
    staticContext ? staticContext.target : {}
  );
  const [image, setImage] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        //let {data} =await axios.get(`${process.env.REACT_APP_API}/data/projets_${lang}.json`)
        let { data } = await axios.get(
          process.env.REACT_APP_API + "/projets?slug=" + id
        );
        if (!data) throw new Error("No Data");
        return setTargetProj(data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, [id]);
  console.log(targetProjet.content);
  if (!targetProjet) return <main></main>;
  return (
    <>
      <main id={"projet-" + id} className="padding-body">
        <div className="back-container">
          <ParalaxButon>
            <Link to="/">
              <div className="goback">
                <div>Home</div>
              </div>
            </Link>
          </ParalaxButon>
        </div>
        <div className="projet-split-container">
          <div className="images-view">
            {targetProjet.thumbnails ? (
              <AlbumImageContainer
                images={targetProjet.thumbnails}
                setImage={setImage}
              />
            ) : null}
          </div>
          <div className="projet-content">
            <div className="title-container-projet">
              <h2>{targetProjet.title}</h2>
              <h3>{targetProjet.date}</h3>
            </div>
            <div className="para-container-projet">
              {targetProjet.content
                ? targetProjet.content.map((ctn) => {
                    return React.createElement(
                      contentTable.find((e) => e.type == ctn.__component)
                        .component,
                      ctn
                    );
                  })
                : null}
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="title-container-projet">
          <h2>Projets Similaires</h2>
          <br />
        </div>
        <div className="projet-similars">
          {targetProjet?.sugestions?.map((prj, i) => {
            return <SimilarProject {...prj} key={i} />;
          })}
        </div>
      </main>
      <ImgPreview event={image} />
    </>
  );
}
