import React, { useEffect, useRef, useState } from "react";
import AlbumImageItem from "./AlbumImageItem";
import ImageSlider from "./ImageSlider";
import Slider from "react-slick";
import "./index.css";

export default function AlbumImageContainer({ images, setImage }) {
  const [imagesList, setImages] = useState(images);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewBoxHTML, setViewBoxHTML] = useState([]);
  const sliderRef = useRef();
  console.log(sliderRef);

  useEffect(() => {
    const viewBoxHTMLTemp = imagesList.map((imagesItem, key) => {
      return (
        <AlbumImageItem
          styleCustom={{
            opacity: key > currentImage + 1 ? 0 : 1,
          }}
          zIndex={9 + imagesList.length - key}
          isPrev={key < currentImage}
          isCurrent={key == currentImage}
          isNext={key == currentImage + 1}
          key={key}
          data={imagesItem}
        ></AlbumImageItem>
      );
    });
    setViewBoxHTML(viewBoxHTMLTemp);
    setImages(images);
  }, [images, currentImage]);

  return (
    <div className="album-image-container">
      <ImageSlider
        sliderRef={sliderRef}
        current={currentImage}
        images={imagesList}
        setCurrent={setCurrentImage}
        setImage={setImage}
      />
      <div className="album-list-preview">
        {imagesList.map((image, key) => {
          return (
            <div
              key={key}
              className={
                key == currentImage
                  ? "list-preview-item list-preview-item-current"
                  : "list-preview-item"
              }
              onClick={() => {
                setCurrentImage(key);
                if (sliderRef.current) {
                  sliderRef.current.slickGoTo(key);
                }
              }}
            >
              <img
                src={`${process.env.REACT_APP_API}${image.formats.thumbnail.url}`}
                alt="preview-image"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
