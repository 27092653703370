import React from "react";
import NavBar from "../../components/header/navigation";
import Logo from "../../components/header/logo";
import { Link } from "react-router-dom";
import "./header.css";

export default function HeaderNav({ logo }) {
  return (
    <header className="header">
      <Link to="/">
        <Logo logo={logo} />
      </Link>
      <NavBar />
    </header>
  );
}
