import React, { useEffect, useState } from "react";

import Background from "../../components/slider/background";
import Info from "../../components/slider/projectInfo";

function SliderItem({ lang, current }) {
  return (
    <>
      <div className="slider_item" id={"slider_item_" + current.id}>
        <Background
          url={`${process.env.REACT_APP_API}${current.thumbnails[0].url}`}
        />
        <div className="overBack">
          <Info {...current} lang={lang} />
        </div>
      </div>
    </>
  );
}

export default SliderItem;
