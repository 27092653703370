
import React, {useState} from 'react'

export default function ParalaxButon({children,onClick=()=>{}}) {
    let [style,setStyle] = useState({})
    function Paralax(event){
        let pos = event.target.getBoundingClientRect()
        let x = event.clientX - pos.left - pos.width / 2
        let y = event.clientY - pos.top - pos.height / 2 

        setStyle({
            transform:"translate("+x*0.5+"px,"+y*0.5+"px)"
        })

    }
    function out(){
        setStyle({
            transition:"0.2s",
            transform:"translate(0px,0px)"
        })
    }
    return (
        <div onMouseMove={Paralax} onMouseOut={out} onClick={onClick} style={style}>
            {children}
        </div>
    )
}
