import { useEffect, useState } from "react";
//styling
import styles from "./index.css";

const ImgPreview = ({ event }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    setError(null);
    console.log("event =", event);
    if (!event) return;
    if (event.x && event.y) {
      console.log("Seting index 100");
      setCurrent(event.current);
      setImages(event.images);
      setHeight(100);
      setTimeout(() => {
        setIsOpen(true);
      }, 300);
    }
  }, [event]);

  useEffect(() => {
    console.log("isOpen = ", isOpen);

    if (!isOpen) {
      setTimeout(() => {
        console.log("Seting index -1");
        setHeight(0);
      }, 300);
    } else {
      setHeight(100);
    }
  }, [isOpen]);

  const next = (e) => {
    e.stopPropagation();
    if (current >= images.length - 1) {
      setCurrent(0);
    } else {
      setCurrent((c) => c + 1);
    }
  };
  const prev = (e) => {
    e.stopPropagation();
    if (current <= 0) {
      setCurrent(images.length - 1);
    } else {
      setCurrent((c) => c - 1);
    }
  };
  return (
    <div className="previewContainer" style={{ height: height + "vh" }}>
      <div
        style={{
          transformOrigin: `${event ? event.x : 0}px ${event ? event.y : 0}px`,
          transform: `scale(${isOpen ? 1 : 0})`,
        }}
        className="previewAnimation"
      >
        <div className="controlsContainer" onClick={() => setIsOpen(false)}>
          <div className="controlBTN">
            <div onClick={(e) => next(e)}></div>
            <div onClick={(e) => prev(e)}></div>
          </div>
          <div className="imgContainer">
            {error ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  color: "rgba(255,255,255,0.5)",
                  fontSize: "20px",
                }}
              >
                <span>{error}</span>
              </div>
            ) : (
              <>
                {event ? (
                  <img
                    src={
                      event.images
                        ? process.env.REACT_APP_API + event.images[current].url
                        : ""
                    }
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgPreview;
