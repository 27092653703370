import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

export default function SimilarProject({
  slug,
  title,
  description,
  thumbnails,
}) {
  return (
    <div className="similar-item">
      <Link to={"/project/" + slug}>
        <div
          className="img-container-similar"
          style={{
            backgroundImage:
              "url(" + `${process.env.REACT_APP_API}${thumbnails[0].url}` + ")",
          }}
        ></div>
        <h4>{title}</h4>
        <p>{description}</p>
      </Link>
    </div>
  );
}
