import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MainTitle from "../globals/title/MainTitle";
import axios from "axios";
import "./index.css";

export default function ProjetSection({ lang, staticContext, data }) {
  return (
    <section id="projects">
      <div style={{ padding: "0 15%" }}>
        <MainTitle>projects</MainTitle>
      </div>
      {data ? (
        <ProjectsList lang={lang} staticContext={staticContext} data={data} />
      ) : null}
    </section>
  );
}

function ProjectsList({ lang, staticContext, data }) {
  let [projects, setProj] = useState(
    staticContext ? staticContext.projects[lang] : []
  );

  const [settings, setSet] = useState({
    dots: true,
    arrows: false,
    infinite: data?.infinite,
    speed: 500,
    slidesToShow: Number.isInteger(Number(data.slidesToShow))
      ? Number(data.slidesToShow)
      : 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: Number.isInteger(Number(data.autoplaySpeed))
      ? Number(data.autoplaySpeed)
      : 5000,
    responsive: data.Breaks.map((b) => {
      let t = {
        breakpoint: Number.isInteger(Number(b.Break)) ? Number(b.Break) : 500,
        settings: {
          slidesToShow: Number.isInteger(Number(b.slideToShow))
            ? Number(b.slideToShow)
            : 5,
        },
      };
      return t;
    }),
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API + "/projets?_sort=order:ASC")
      .then((res) => {
        setProj(res.data);
      });
  }, []);

  let projetsHTML = projects.map((p, k) => {
    return <ProjectItem key={k} {...p} />;
  });
  console.log(settings);
  return (
    <Slider className="project-container" {...settings}>
      {projetsHTML}
    </Slider>
  );
  //return <div className="project-container">{projetsHTML}</div>;
}

function ProjectItem({ slug, title, date, thumbnails }) {
  let url = "/project/" + slug;
  let img = "url(" + `${process.env.REACT_APP_API}${thumbnails[0].url}` + ")";
  return (
    <div>
      <div className="project_item" style={{ backgroundImage: img }}>
        <div className="unhover">
          <h2>{title}</h2>
          <div className="horitext">
            <span>{date}</span>
          </div>
        </div>

        <div className="hover">
          <Link to={url}>
            <div className="button">
              <span>DECOUVRIR</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
