import React, { useEffect, useState } from "react";
import { SliderContainer as Slider } from "../Slider/SliderContainer";
import axios from "axios";
import AboutSection from "../../components/home/AboutSection";
import ProjetSection from "../../components/home/ProjetSection";
import ContactSection from "../../components/home/ContactSection";
export default function HomePageContainer({ lang, staticContext }) {
  const [texts, setTexts] = useState(
    staticContext ? staticContext.main[lang] : {}
  );
  const [waitRender, setWaitRender] = useState(staticContext ? 0 : 600);
  useEffect(() => {
    const getData = async () => {
      //const {data} = await axios.get(`${process.env.REACT_APP_API}/data/index.json`)
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/home-page`
      );
      //if (!data || !lang) return;
      setTexts(data);
    };
    getData();
  }, [lang]);

  useEffect(() => {
    setTimeout(() => {
      setWaitRender(0);
    }, waitRender);
  }, []);
  console.log(texts);
  return (
    <div>
      <Slider lang={lang} staticContext={staticContext}></Slider>

      {!waitRender ? (
        <>
          <div>{texts.about ? <AboutSection data={texts.about} /> : null}</div>
          <ProjetSection
            lang={lang}
            staticContext={staticContext}
            data={texts.Projets}
          />
          {texts.Contact ? (
            <ContactSection lang={lang} data={texts.Contact} />
          ) : null}
        </>
      ) : null}
    </div>
  );
}
