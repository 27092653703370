import React from "react";
import MainTitle from "../globals/title/MainTitle";
import "./index.css";
export default function AboutSection({ data: about }) {
  console.log("a", about);
  if (!about) return <div></div>;
  try {
    return (
      <section id="about" className="padding-body">
        <MainTitle>about</MainTitle>
        <div className="about-container-split">
          <div className="text-about">
            <h3>Mon Parcours</h3>
            <p>{about.parcours}</p>
            <h3>Services</h3>
            <div className="services-list">
              {about.service.map(({ name, image }, key) => {
                return (
                  <div key={key} className="service-container">
                    <span className="service-name">{name}</span>
                    <div className="img-container-service">
                      <img
                        src={`${process.env.REACT_APP_API}${image.url}`}
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="illustration">
            <img
              src={`${process.env.REACT_APP_API}${about.illustration.formats.large.url}`}
              alt="illu"
            />
          </div>
        </div>
      </section>
    );
  } catch (error) {
    console.log(error);
    return <section id="about" className="padding-body"></section>;
  }
}
