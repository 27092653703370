import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import insta from "../../assets/svg/instagram.svg";
import twitter from "../../assets/svg/twitter.svg";
import pinter from "../../assets/svg/pinterest.svg";
import prev from "../../assets/svg/prev_arrow.svg";
import next from "../../assets/svg/next_arrow.svg";
import ParalaxButon from "../butons/ParalaxButon";
import axios from "axios";

function parseNumber(nb) {
  if (nb > 9) return nb;
  return "0" + nb;
}

function AsideSlider({ arrows, current, length, Prev, Next, setPos }) {
  const [networks, setNetworks] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        let {
          data: { network: networks },
        } = await axios.get(`${process.env.REACT_APP_API}/networks`);
        if (!networks) throw new Error("No data");
        setNetworks(networks);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  return (
    <aside className="asideSlider">
      <div className="network">
        {networks.map((net) => {
          return (
            <ParalaxButon>
              <a href={net.url} target="_blank">
                <div className="net-container">
                  <span>{net.logo.alternativeText}</span>
                  <img
                    src={`${process.env.REACT_APP_API}${net.logo.url}`}
                    alt={net.logo.alternativeText}
                  />
                </div>
              </a>
            </ParalaxButon>
          );
        })}

        <div className="line_end"></div>
      </div>
      <div className="page_info">
        <div className="current">
          <div
            className="border-arrow"
            onClick={
              arrows[0]
                ? () => Prev()
                : () => {
                    setPos(length - 1);
                  }
            }
          >
            <ParalaxButon>
              <div className="arrow_left" style={{ opacity: true ? 0.6 : 0.2 }}>
                <img src={prev} alt="" />
              </div>
            </ParalaxButon>
          </div>
          <div className="counter">{parseNumber(current)}</div>
          <div
            className="border-arrow"
            onClick={
              arrows[1]
                ? () => Next()
                : () => {
                    setPos(0);
                  }
            }
          >
            <ParalaxButon>
              <div
                className="arrow_right"
                style={{ opacity: true ? 0.6 : 0.2 }}
              >
                <img src={next} alt="" />
              </div>
            </ParalaxButon>
          </div>
        </div>
        <div className="lengthCount">/ {parseNumber(length)}</div>
      </div>
    </aside>
  );
}

export default AsideSlider;
