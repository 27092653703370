import React from "react";

function NavSlider({ current, length, setPos }) {
  let lines = [];

  for (let i = 0; i < length; i++) {
    lines.push(
      <div
        key={i}
        onClick={() => setPos(i)}
        className={
          current === i ? "currentPrj NavSlider_items" : "NavSlider_items"
        }
      ></div>
    );
  }

  return <nav className="navSlider">{lines}</nav>;
}

export default NavSlider;
