import React, { useState, useEffect, Component } from "react";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import HeaderNav from "./containers/header/HeaderNav";
import Footer from "./containers/footer/Footer";
import ProjectContainer from "./containers/project";
import HomePageContainer from "./containers/Home/HomePageContainer";
import AutoHtmlPageContainer from "./containers/autoHtmlPage/AutoHtmlPageContainer";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import axios from "axios";

import "./App.css";
import ImageSlider from "./components/AlbumImage/ImageSlider";

function App() {
  const [lang, setLang] = useState("fr");
  const [mainInfo, setMainInfo] = useState({
    color: "#fffff",
    logo: "AG Architecture",
    copyright: "",
  });
  let location = useLocation();
  useEffect(() => {
    const getData = async () => {
      try {
        let { data } = await axios.get(
          `${process.env.REACT_APP_API}/global-setitngs`
        );
        if (!data) throw new Error("No data");
        setMainInfo(data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  return (
    <div style={{ ["--secondary-color"]: mainInfo.color }} id="main-container">
      <HeaderNav logo={mainInfo.logo} />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={500}>
          <Switch>
            <Route
              path="/project/:id"
              render={({ staticContext = null }) => {
                console.log(staticContext);
                return (
                  <div>
                    <ProjectContainer
                      lang={lang}
                      staticContext={staticContext}
                    />
                  </div>
                );
              }}
            ></Route>

            <Route
              path="/:page"
              render={({ staticContext = null }) => {
                return (
                  <div>
                    <AutoHtmlPageContainer staticContext={staticContext} />
                  </div>
                );
              }}
            ></Route>

            <Route
              path="/"
              render={({ staticContext = null }) => {
                return (
                  <div>
                    <HomePageContainer
                      lang={lang}
                      staticContext={staticContext}
                    />
                  </div>
                );
              }}
            ></Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer logo={mainInfo.logo} copyright={mainInfo.copyright} />
    </div>
  );
}
try {
  if (window) {
    window.addEventListener("mousemove", (e) => {
      let banners = document.querySelectorAll(".background");
      if (!banners) return;
      let xx = 50 - (e.pageX - window.innerWidth) * 0.005;
      let yy = 50 - (e.pageY - window.innerHeight) * 0.005;
      banners.forEach((e) => {
        try {
          e.style.backgroundPosition = xx + "% " + yy + "%";
        } catch (error) {}
      });
    });
  }
} catch (error) {
  console.log("BROWSER NO");
}

export default App;
