import React, { useState } from "react";
import MainTitle from "../globals/title/MainTitle";
import Map from "../map/Map";
import axios from "axios";
import "./index.css";
export default function ContactSection({ lang, data }) {
  console.log("c", data);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [succesMessage, setSuccesMessage] = useState(null);
  const handleSubmit = async (e) => {
    if (isLoading) return;
    setIsLoading(true);
    setSuccesMessage(null);
    e.preventDefault();
    let data = new FormData(document.getElementById("contact-form"));
    try {
      let d = {};
      data.forEach((e, k) => {
        d[k] = e;
      });
      let res = await axios({
        url: process.env.REACT_APP_API + "/contact-form-plugin/sendMessage",
        method: "POST",
        data: d,
        validateStatus: function (status) {
          return (status >= 200 && status < 400) || status == 405;
        },
      });
      setIsLoading(false);
      if (res.status > 400) {
        setErrorMessage(res.data.error);
      } else {
        let inputs = document.querySelectorAll(
          "input[type=text],input[type=email]"
        );
        document.querySelector("textarea").value = "";
        let labels = document.querySelectorAll("label");
        inputs.forEach((i) => (i.value = ""));
        labels.forEach((l) => l.classList.remove("cacheLabel"));
        setErrorMessage(null);
        setSuccesMessage("Votre message a bien été envoyé !");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleFocus = ({ currentTarget }, focus) => {
    let label = currentTarget.parentNode.firstChild;
    if (focus) {
      label.classList.add("cacheLabel");
    } else {
      if (currentTarget.value != "") return;
      label.classList.remove("cacheLabel");
    }
  };
  return (
    <section id="contact" className="padding-body">
      <MainTitle>contact</MainTitle>
      <div className="contact-split-container">
        <div className="contact-form-container">
          <form onSubmit={handleSubmit} id="contact-form">
            <div className="line-form">
              <div className="input-form">
                <label htmlFor="contact-nom">
                  {lang == "fr" ? "Nom" : "Last Name"}
                </label>
                <input
                  onChange={({ currentTarget }) => {
                    if (currentTarget.checkValidity())
                      currentTarget.parentNode.classList.remove("erroned");
                  }}
                  required
                  onInvalid={({ currentTarget }) =>
                    currentTarget.parentNode.classList.add("erroned")
                  }
                  onFocus={(e) => handleFocus(e, true)}
                  onBlur={(e) => handleFocus(e, false)}
                  type="text"
                  id="contact-nom"
                  name="lastName"
                />
              </div>
              <div className="input-form">
                <label htmlFor="contact-prenom">
                  {lang == "fr" ? "Prénom" : "First Name"}
                </label>
                <input
                  onChange={({ currentTarget }) => {
                    if (currentTarget.checkValidity())
                      currentTarget.parentNode.classList.remove("erroned");
                  }}
                  required
                  onInvalid={({ currentTarget }) =>
                    currentTarget.parentNode.classList.add("erroned")
                  }
                  onFocus={(e) => handleFocus(e, true)}
                  onBlur={(e) => handleFocus(e, false)}
                  type="text"
                  id="contact-prenom"
                  name="firstName"
                />
              </div>
            </div>
            <div className="line-form">
              <div className="input-form">
                <label htmlFor="contact-mail">
                  {lang == "fr" ? "Email" : "Email"}
                </label>
                <input
                  onChange={({ currentTarget }) => {
                    if (currentTarget.checkValidity())
                      currentTarget.parentNode.classList.remove("erroned");
                  }}
                  required
                  onInvalid={({ currentTarget }) =>
                    currentTarget.parentNode.classList.add("erroned")
                  }
                  onFocus={(e) => handleFocus(e, true)}
                  onBlur={(e) => handleFocus(e, false)}
                  type="email"
                  id="contact-mail"
                  name="email"
                />
              </div>
            </div>
            <div className="line-form">
              <div className="input-form">
                <label htmlFor="contact-subject">
                  {lang == "fr" ? "Objet" : "Subject"}
                </label>
                <input
                  onChange={({ currentTarget }) => {
                    if (currentTarget.checkValidity())
                      currentTarget.parentNode.classList.remove("erroned");
                  }}
                  required
                  onInvalid={({ currentTarget }) =>
                    currentTarget.parentNode.classList.add("erroned")
                  }
                  onFocus={(e) => handleFocus(e, true)}
                  onBlur={(e) => handleFocus(e, false)}
                  type="text"
                  id="contact-subject"
                  name="subject"
                />
              </div>
            </div>
            <div className="line-form">
              <div className="input-form">
                <label htmlFor="contact-msg">
                  {lang == "fr" ? "Message" : "Message"}
                </label>
                <textarea
                  onChange={({ currentTarget }) => {
                    if (currentTarget.checkValidity())
                      currentTarget.parentNode.classList.remove("erroned");
                  }}
                  required
                  onInvalid={({ currentTarget }) =>
                    currentTarget.parentNode.classList.add("erroned")
                  }
                  onFocus={(e) => handleFocus(e, true)}
                  onBlur={(e) => handleFocus(e, false)}
                  type="text"
                  id="contact-msg"
                  name="message"
                ></textarea>
              </div>
            </div>
            <span className="error-message">
              {errorMessage ? errorMessage : null}
            </span>
            <span className="succes-message">
              {succesMessage ? succesMessage : null}
            </span>
            <div className="line-form">
              <div className="input-form">
                <input
                  disabled={isLoading}
                  type="submit"
                  value={
                    isLoading ? "Envoi ..." : lang == "fr" ? "Envoyer" : "Send"
                  }
                />
              </div>
            </div>
          </form>
        </div>
        <div className="contact-form-info">
          <div>
            <Map coord={{ lat: data.lat, lng: data.lng }} />
          </div>
          <div className="text-address">
            <span>{data.city}</span>
            <span>{data.country}</span>
            <span>{data.phone}</span>
            <span>
              <a href={"mailto:" + data.mail}>{data.mail}</a>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
