import ReactMarkdown from "react-markdown";

const Paragraph = ({ content }) => {
  return (
    <div className="paragraph-content">
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

export default Paragraph;
