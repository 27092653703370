import React from "react";

function Background({url}){

    return (
        <div className="background" style={{backgroundImage:"url("+url+")"}}>
            
        </div>
    )
}

export default Background
