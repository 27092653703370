import React, { useState } from "react";
import Slider from "react-slick";
import "./indexSlider.css";
const ImageSlider = ({ images, current, setCurrent, sliderRef, setImage }) => {
  const options = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerPadding: "20px",
    infinite: false,
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    afterChange: (c) => {
      setCurrent(c);
    },
  };

  let slider = (
    <Slider ref={sliderRef} {...options} className="ImageSlider-container">
      {images.map((image, k) => {
        return (
          <div
            className={
              "ImageSlider-Item" + (k == current ? " ImageSlider-Current" : "")
            }
            onClick={(e) => {
              setCurrent(k);
            }}
            onDoubleClick={(e) => {
              if (k == current) {
                setImage({
                  x: e.clientX,
                  y: e.clientY,
                  img: process.env.REACT_APP_API + image.url,
                  images: images,
                  current: current,
                });
              }
            }}
          >
            <div>
              <img src={`${process.env.REACT_APP_API}${image.url}`} alt="" />
            </div>
          </div>
        );
      })}
    </Slider>
  );

  return <>{slider}</>;
};
/* const ImageSlider = ({ images, current, setCurrent }) => {
  return (
    <>
      <div className="ImageSlider-container">
        {images.map((image, k) => {
          console.log(current);
          let posOfCurrent = k < current ? k - current : k - current;
          let tfx = 15 + (70 + 25 - 30) * posOfCurrent;
          let rotate = 0;
          let rotateZ = 0;
          
          return (
            <div
              className={"ImageSlider-Item "}
              style={{
                zIndex: current == k ? 2 : 1,
                transform: `translateX(${tfx}%) scale(${
                  current == k ? 1.1 : 0.5
                }) rotateY(${rotate}) rotateZ(${rotateZ})`,
                cursor: current == k ? "" : "pointer",
              }}
              onClick={() => setCurrent(k)}
            >
              <div>
                <img src={`${process.env.REACT_APP_API}${image.url}`} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}; */

export default ImageSlider;
